import HomeRoute from "./routes/home";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00ff00',
        },
        secondary: {
            main: '#ef2e2a',
        },
        textSecondary: { main: '#f00' },
    },
    typography: {
        fontFamily: ['Monospace'],
        color: 'red',
        h1: {
            fontSize: '2.5rem'
        },
        h2: {
            fontSize: '2.3rem'
        },
        h3: {
            fontSize: '2.15rem'
        },
        h4: {
            fontSize: '2rem'
        },
    },
});

function App() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <HomeRoute />
            </ThemeProvider>
        </>
    );
}

export default App;
