import Box from '@mui/material/Box';
import {Typography, Select, MenuItem, Button} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import lanBois from './lan-bois.js';
import lanlolLogo from './lanlol-logo-transparant.svg';
import './scanner-overlay.css';

const faceapi = window.faceapi;

const HomeRoute = () => {
    const videoRef = useRef();
    const canvasRef = useRef();
    const audioRef = useRef();

    const faceMatcher = useRef(undefined);
    const faceDetectInterval = useRef(undefined);

    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [devices, setDevices] = useState([]);

    const [cameraStatus, setCameraStatus] = useState('offline'); // offline|initializing|error|online
    const [faceApiStatus, setFaceApiStatus] = useState('offline'); // offline|initializing|paused|error|online
    const [audioPlayerStatus, setAudioPlayerStatus] = useState('offline'); // offline|initializing|error|online
    const [systemStatus, setSystemStatus] = useState('initializing'); // offline|initializing|announcing|scanning
    const [announcingLanBoi, setAnnouncingLanBoi] = useState(null);

    async function playSoundFile(fileUrl) {
        try {
            audioRef.current.src = fileUrl;
            //console.log(audioRef.current.duration);
            audioRef.current.play();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (cameraStatus === 'online' && faceApiStatus === 'online' && audioPlayerStatus === 'online') {
            setSystemStatus('scanning');
            startFaceDetector();
        }
    }, [cameraStatus, faceApiStatus, audioPlayerStatus]);

    function startFaceDetector() {
        faceDetectInterval.current = window.setInterval(async () => {
            await runFaceDetector();
        }, 1000);
    }

    function pauseFaceDetector() {
        if (faceDetectInterval.current !== undefined) {
            window.clearInterval(faceDetectInterval.current);
            faceDetectInterval.current = undefined;
        }
    }

    async function onLanBoiDetect(lanBoi) {
        if (lanBoi?.audioFileName !== undefined) {
            pauseFaceDetector();
            setSystemStatus('announcing');
            setAnnouncingLanBoi(lanBoi);
            await playSoundFile(`/audio/${lanBoi.audioFileName}`);
        }
    }

    // init audioPlayer
    useEffect(() => {
        try {
            setAudioPlayerStatus('initializing');

            audioRef.current.addEventListener('play', () => {

            });

            audioRef.current.addEventListener('ended', () => {

                setSystemStatus('scanning');
                setAnnouncingLanBoi(null);
                startFaceDetector();
            });

            setAudioPlayerStatus('online');
        } catch (error) {
            setAudioPlayerStatus('error');
            console.error(error);
        }
    }, []);

    // init face api
    useEffect(async() => {
        try {
            setFaceApiStatus('initializing');
            const MODEL_URL = '/weights';

            await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
            await faceapi.loadFaceLandmarkModel(MODEL_URL);
            await faceapi.loadFaceRecognitionModel(MODEL_URL);

            const labeledFaceDescriptors = await Promise.all(
                lanBois.map(async lanboi => {
                    // fetch image data from urls and convert blob to HTMLImage element
                    const imgUrl = `/photos/${lanboi.photoFileName}`
                    const img = await faceapi.fetchImage(imgUrl)

                    // detect the face with the highest score in the image and compute it's landmarks and face descriptor
                    const fullFaceDescription = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor()

                    if (!fullFaceDescription) {
                        throw new Error(`no faces detected for ${lanboi.nickName}`)
                    }

                    const faceDescriptors = [fullFaceDescription.descriptor]
                    return new faceapi.LabeledFaceDescriptors(lanboi.nickName, faceDescriptors)
                })
            );
            //setLabeledFaceDescriptors(labeledFaceDescriptors);

            const maxDescriptorDistance = 0.6;
            faceMatcher.current = new faceapi.FaceMatcher(labeledFaceDescriptors, maxDescriptorDistance);

            setFaceApiStatus('online');
        } catch (error) {
            console.error(error);
            setFaceApiStatus('error');
        }
    }, []);

    async function runFaceDetector() {
        let result = await faceapi.detectAllFaces(videoRef.current).withFaceLandmarks().withFaceDescriptors();
        const dimensions = faceapi.matchDimensions(canvasRef.current, videoRef.current, true);
        result = faceapi.resizeResults(result, dimensions);

        if (result) {
            faceapi.draw.drawDetections(canvasRef.current, result);
            faceapi.draw.drawFaceLandmarks(canvasRef.current, result);
        }

        const results = result.map(fd => faceMatcher.current.findBestMatch(fd.descriptor));
        let detectedLanBoi = undefined;

        results.forEach((bestMatch, i) => {
            const box = result[i].detection.box;
            const text = bestMatch.toString();
            const drawBox = new faceapi.draw.DrawBox(box, { label: text });
            drawBox.draw(canvasRef.current);

            if (detectedLanBoi === undefined) {
                detectedLanBoi = lanBois.find((lanBoi) => {
                    return lanBoi.nickName === bestMatch._label;
                });
            }
        });

        if (detectedLanBoi !== undefined) {
            await onLanBoiDetect(detectedLanBoi);
        }
    }

    useEffect(async() => {
        if (selectedDeviceId !== '') {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: {
                        exact: selectedDeviceId
                    }
                }
            });
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            setCameraStatus('online');
        }
    }, [selectedDeviceId]);

    // get available devices
    useEffect(() => {
        async function getDevices() {
            try {
                setCameraStatus('initializing');
                if (navigator?.mediaDevices?.getUserMedia === undefined
                    || navigator?.mediaDevices?.enumerateDevices === undefined
                ) {
                    // todo
                    //setShowWebcamNotSupportedDialog(true);
                } else {
                    await navigator.mediaDevices.getUserMedia({
                        video: {
                            width: 1920,
                            height: 1080,
                        }
                    });
                    const devices = await navigator?.mediaDevices?.enumerateDevices() ?? undefined;
                    const videoDevices = devices.filter(({ kind }) => kind === 'videoinput');

                    setDevices(videoDevices);
                    if (devices.length === 0) {
                        // todo
                        //userNotifierContext.setError(t("Geen beschikbare camera's gevonden"));
                    } else {
                        window.setTimeout(() => {
                            const firstFrontVideoDeviceFound = videoDevices.find((device) => {
                                return device.label.toLowerCase().includes('back');
                            });
                            if (firstFrontVideoDeviceFound) {
                                setSelectedDeviceId(firstFrontVideoDeviceFound.deviceId);
                            } else {
                                setSelectedDeviceId(videoDevices[0].deviceId);
                            }
                        }, 1000);
                    }
                    setCameraStatus('online');
                }
            } catch (error) {
                console.error(error);
                setCameraStatus('error');
                // todo
                // userNotifierContext.setError(t(error.message));
            }
        }
        getDevices();
        // eslint-disable-next-line
    }, []);

    const deviceSelectOptions = devices.map((device) => {
        return {
            title: device.label,
            value: device.deviceId,
        }
    });

    return <>
        <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'stretch'
        }}>
            <audio
                ref={audioRef}
                style={{
                    display: 'none'
                }}
                controls
            ></audio>
            <Box sx={{
                width: '70%',
                height: '100%',
                backgroundColor: '#000',
                position: 'relative',
            }}>
                <video
                    ref={videoRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                    }}
                >
                </video>

                <canvas
                    ref={canvasRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                    }}
                >
                </canvas>

                <div className={`scanner-overlay ${systemStatus}`}></div>

                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}>
                    {/*<Button onClick={() => {*/}
                    {/*    runFaceDetector();*/}
                    {/*}}>Detect</Button>*/}

                    {/*<Button onClick={() => {*/}
                    {/*    playSoundFile('/audio/edwin.webm');*/}
                    {/*}}>Play file</Button>*/}
                </Box>

            </Box>
            <Box sx={{
                width: '30%',
                height: '100%',
                backgroundColor: '#2f3136',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                border: '1px #2f3136 solid'
            }}>
                <Box sx={{
                    height: '20%',
                }}>
                    <Typography variant="h1" color="primary" marginTop>
                        Lanboi announcer 9000
                    </Typography>
                </Box>

                <Box sx={{
                    height: '20%',
                }}>
                    <Typography color="primary" marginTop>
                        Put your ugly face in front of the camera, and get announced as you walk the stairs.
                    </Typography>
                </Box>

                <Box sx={{
                    height: '30%',
                }}>
                    <Typography variant="h2" color="secondary" marginTop>
                        Status :&nbsp;
                        {systemStatus === 'announcing' && announcingLanBoi !== null &&
                        <>Announcing the one and only <strong>{announcingLanBoi.nickName}</strong></>
                        }
                        {systemStatus !== 'announcing' &&
                        <>{systemStatus}</>
                        }
                    </Typography>
                    {systemStatus === 'announcing' &&
                        <Button sx={{
                            marginTop: '20px'
                        }} variant="contained" color="secondary" onClick={() => {
                            audioRef.current.pause();
                            setAnnouncingLanBoi(null);
                            setSystemStatus('scanning');
                            startFaceDetector();
                        }}>Stop/cancel announcement</Button>
                    }
                </Box>

                <Box sx={{
                    height: '25%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end'
                }}>
                    <Box sx={{
                        width: '65%'
                    }}>
                        <Select
                            value={selectedDeviceId ?? ''}
                            onChange={(event) => {
                                setSelectedDeviceId('');
                                window.setTimeout(() => {
                                    setSelectedDeviceId(event.target.value);
                                }, 500); // add a delay to allow the current qr-reader to dismount
                            }}
                        >
                            {deviceSelectOptions.map((option, key) => <MenuItem key={key} value={option.value}>{option.title}</MenuItem>)}
                        </Select>
                        <Typography color="primary" marginTop>Camera : {cameraStatus}</Typography>
                        <Typography color="primary" >Face detector : {faceApiStatus}</Typography>
                        <Typography color="primary">Audio player  : {audioPlayerStatus}</Typography>
                    </Box>
                    <Box style={{
                        width: '30%'
                    }}>
                        <img
                            style={{
                                width: '100%'
                            }}
                            src={lanlolLogo} alt="LANLOL.nl"
                        />
                    </Box>
                </Box>

            </Box>
        </Box>
    </>
}

export default HomeRoute;
