const lanBois = [{
    nickName: 'Bossie',
    photoFileName: 'edwin.jpeg',
    audioFileName: 'hail-to-the-king.webm'
}, {
    nickName: 'HappyMartin',
    photoFileName: 'martin.jpeg',
    audioFileName: 'hatsune-miku.webm'
}, {
    nickName: 'The Godfather',
    photoFileName: 'bram.jpeg',
    audioFileName: 'astrix-deep-jungle-walk.mp3'
}, {
    photoFileName: 'berdien.jpeg',
    audioFileName: 'my-hands-are-shaking-boom-headshot.webm',
    nickName: 'Berdien'
}];

export default lanBois;
